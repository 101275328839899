import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({location}) =>
(
  <Layout>

    <SEO title="Porsche 911 991 GTS prices - at their peak?"
      description="A customer is considering a purchase of a 991.1 or 991.2 GTS (plus a couple of other options), and requested our opinion on price changes over the past 12 months, and whether now was a good or bad time to buy"
      location={location}
    />

    <PageHeader
      title="Porsche 911 991 GTS prices - at their peak?"
      text="A customer is considering a purchase of a 991.1 or 991.2 GTS (plus a couple of other options), and requested our opinion on price changes over the past 12 months, and whether now was a good or bad time to buy"
    />

    <SectionPageMargin>

      <ContentStyle>
        <Heading2>Porsche 991.1 Carrera GTS prices</Heading2>

        <Paragraph1>
          Last updated: May 2021
        </Paragraph1>

        <Paragraph1>
          Unsurprisingly, along with many prestige cars, prices are sky-high right now due to lack of supply (in particular new car deliveries) x plenty of demand (low interest rates, missed expensive holidays etc).
        </Paragraph1>
  
        <Paragraph1>
          Looking at some data (comparing 6 month periods May 2020 - Oct 2020 vs Nov 2020 to May 2021) we discovered some surprising facts about Porsche 911 GTS prices in Australia;
        </Paragraph1>


        <Research>
          <strong>991.1 Carrera GTS</strong> - Increased in value $12k - $15k: 
          <ol>
            <li>First 6 months average price $218k, average mileage of 21,000km </li>
            <li>2nd 6 months average price $230k, average mileage of 32,000km</li>
          </ol>
        </Research>

        <Research>
          <strong>991.2 Carrera GTS</strong> - Increased in value $10k - $15k: 
          <ol>
            <li>First 6 months average price $252k, average mileage of 14,000km </li>
            <li>2nd 6 months average price $262k, average mileage of 18,000km</li>
          </ol>
        </Research>

        
        <Research>
          <strong>991.2 Carrera T</strong> - Big increase in value of $26k - $38k:  
          <ol>
            <li>First 6 months average price $207k, average mileage of 11,500km </li>
            <li>2nd 6 months average price $233k, average mileage of 15,000km.</li>
            <li>Now asking (not sold) prices are $245k for new listings</li>
          </ol>
        </Research>

        <Research>
          <strong>991.1 Carrera 4S </strong> -  Held value:  
          <ol>
            <li>First 6 months average price $180k, average mileage of 54,000km</li>
            <li>2nd 6 months average price $176k, average mileage of 50,000km</li>
          </ol>
        </Research>

        <Paragraph1>
         Considering the cars above are all reasonably new, and in normal scenarios certainly shouldn’t be at the bottom of their depreciation curve - yet most have increased or held their value in the past year. With some seeing significant appreciation (taking into account mileage).
        </Paragraph1>

        <Paragraph1>
        It’s hard to predict where prices will go from here, but it does seem like it might be the top of the market. The best advice I have been told, is to buy the car you love when it comes available, in particular if you are not purchasing as an investment but as something to drive and enjoy.
        </Paragraph1>

        <Paragraph1>
        For more examples of average prices please visit our new <Link to="/price/" title="Price Guides">Price Guides</Link>.
        </Paragraph1>

      </ContentStyle>
    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage

const Research = styled.p`
  margin-top: 30px;
  margin-bottom: 40px;

`;


const ContentStyle = styled.div`
  table {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
    background-color: #f8f8f9;
  }

  tr {
    background-color: white;
    color: black;
  }

  th {
    padding: 10px;
    background-color: #3479ae;
    color: white;
  }

  td {
    padding: 5px;
    padding-left: 15px;
    background-color: #f8f8f9;
  }

  li {
    line-height: 2rem;
  }

`;
